var render = function render(){
  var _vm$program, _vm$program$thumb, _vm$program$category, _vm$unit, _vm$unit2, _vm$unit3, _vm$program$capacity, _vm$program$capacity$, _vm$program2, _vm$program2$meta, _vm$program2$meta$sum;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "w-100 rounded",
    attrs: {
      "src": ((_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$thumb = _vm$program.thumb) === null || _vm$program$thumb === void 0 ? void 0 : _vm$program$thumb.src) || '/images/sub/program/list/program-item-default.jpg',
      "max-width": "440",
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-layout', {
    staticClass: "pl-md-20",
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-space-between": ""
    }
  }, [_c('div', [_c('v-layout', {
    staticClass: "mb-12 mb-md-16",
    attrs: {
      "align-center": ""
    }
  }, [_c('h4', {
    staticClass: "tit pr-10"
  }, [_vm._v(_vm._s(_vm.program.name))]), _vm.program.state == _vm.PROGRAM_STATES.FINISHED.value ? [_c('v-chip', {
    staticClass: "v-chip--noactive",
    attrs: {
      "small": "",
      "dark": "",
      "color": "grey"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.stateText) + " ")])] : [_c('v-chip', {
    staticClass: "v-chip--noactive",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.stateText) + " ")])]], 2), _c('div', {
    staticClass: "mb-n6 mb-md-n10"
  }, [_c('info-row-inline', {
    staticClass: "mb-6 mb-md-10",
    attrs: {
      "title": "교육유형",
      "align": "center"
    }
  }, [_c('v-chip', {
    staticClass: "v-chip--noactive grey--text",
    attrs: {
      "small": "",
      "color": "grey lighten-4"
    }
  }, [_vm._v(_vm._s((_vm$program$category = _vm.program.category) === null || _vm$program$category === void 0 ? void 0 : _vm$program$category.name))])], 1), _c('info-row-inline', {
    staticClass: "mb-6 mb-md-10",
    attrs: {
      "title": "교육대상",
      "align": "center"
    }
  }, [_vm.isForAllUserTypes ? [_c('v-chip', {
    staticClass: "v-chip--noactive",
    attrs: {
      "small": "",
      "color": "secondary"
    }
  }, [_vm._v("전체회원")])] : _vm._l(_vm.userTypeTexts, function (text) {
    return [_c('v-chip', {
      key: text,
      staticClass: "v-chip--noactive",
      attrs: {
        "small": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(text) + " ")])];
  })], 2), _c('info-row-inline', {
    staticClass: "mb-6 mb-md-10",
    attrs: {
      "title": "교육일자"
    }
  }, [_vm._v(" " + _vm._s(_vm.date) + " (" + _vm._s((_vm$unit = _vm.unit) === null || _vm$unit === void 0 ? void 0 : _vm$unit.day) + ") ")]), _c('info-row-inline', {
    staticClass: "mb-6 mb-md-10",
    attrs: {
      "title": "교육시간"
    }
  }, [_vm._v(" " + _vm._s((_vm$unit2 = _vm.unit) === null || _vm$unit2 === void 0 ? void 0 : _vm$unit2.timeStart) + " ~ " + _vm._s((_vm$unit3 = _vm.unit) === null || _vm$unit3 === void 0 ? void 0 : _vm$unit3.timeEnd) + " ")]), _c('info-row-inline', {
    staticClass: "mb-6 mb-md-10",
    attrs: {
      "title": "현재상태"
    }
  }, [_c('span', {
    staticClass: "secondary--text font-weight-medium"
  }, [_vm._v(_vm._s(_vm.program.application.stateText))]), _vm._v(" (총 교육인원 " + _vm._s((_vm$program$capacity = _vm.program.capacity) === null || _vm$program$capacity === void 0 ? void 0 : (_vm$program$capacity$ = _vm$program$capacity.format) === null || _vm$program$capacity$ === void 0 ? void 0 : _vm$program$capacity$.call(_vm$program$capacity)) + "명 / 현재 참여 " + _vm._s(((_vm$program2 = _vm.program) === null || _vm$program2 === void 0 ? void 0 : (_vm$program2$meta = _vm$program2.meta) === null || _vm$program2$meta === void 0 ? void 0 : (_vm$program2$meta$sum = _vm$program2$meta.summary) === null || _vm$program2$meta$sum === void 0 ? void 0 : _vm$program2$meta$sum.applications__active) || 0) + "명) ")])], 1)], 1), _c('div', {
    staticClass: "mt-20"
  }, [_vm._t("action-btn")], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }