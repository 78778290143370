<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <v-row justify="center" class="row--xl">
                <v-col cols="12" xl="6">
                    <v-card outlined class="rounded-lg pa-16 px-md-84 py-md-60">
                        <div class="font-size-24 font-size-md-28 text-center">
                            비밀번호 재설정을 위해 <br />
                            본인인증을 진행해주세요.
                        </div>
                        <v-btn block x-large color="#FF3A4A" class="white--text mt-40 mt-md-48">본인 인증 하기</v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        PageSection,
    },
    data: () => ({}),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
