var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isCanceled ? [_c('span', [_vm._v("취소완료")])] : _vm.isCancelable ? [_c('v-chip', {
    attrs: {
      "dark": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_c('span', {
    staticClass: "font-size-12"
  }, [_vm._v("신청취소")])])] : [_c('v-chip', {
    attrs: {
      "color": "grey lighten-4"
    }
  }, [_c('span', {
    staticClass: "grey--text text--lighten-1 font-size-12"
  }, [_vm._v("신청취소")])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }