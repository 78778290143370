<template>
    <v-sheet class="mb-4">
        <v-row :align="align" class="row--x-small">
            <v-col cols="auto">
                <p class="page-text page-text--lg font-weight-medium">{{title}}</p>
            </v-col>
            <v-col class="page-text page-text--lg grey--text">
                <p v-if="info">{{info}}</p>
                <slot v-else />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default{
    props : {
        title: { type: String, default: "" },
        info: { type: String, default: "" },
        align: { type: String, default: "" },
    },
	components: {
	},
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

<style lang="scss" scoped>
</style>