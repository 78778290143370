<template>
    <client-page class="wrap wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지"></sub-visual>
        <mypage-navigation />

        <page-section v-bind="{ title }" class="page-section--first page-section--last">
            <v-data-table v-bind="{ items, headers, itemClass }" hide-default-footer disable-filtering disable-sort class="v-data-table--default table--primary--large">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.state`]="{ item, value, header }">
                    <template v-if="value == RENTAL_SCHEDULE_STATES.REJECTED.value && item?.desc?.rejection">
                        <v-dialog max-width="480">
                            <template #activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on">
                                    {{ header.formatter(value) }}
                                    <v-icon small>mdi-information-outline</v-icon>
                                </span>
                            </template>
                            <v-card>
                                <v-card-title>거절사유</v-card-title>
                                <v-card-text>
                                    {{ item.desc.rejection }}
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </template>
                    <template v-else>
                        {{ header.formatter(value) }}
                    </template>
                </template>

                <template #[`item.cancel`]="{ item }">
                    <rental-request-cancel-btn :value="item" @input="updateItem" />
                </template>

                <!-- S::출석상태 -->
                <template #[`item.attendance`]="{ value }">
                    <rental-request-item-attendance v-bind="{ value }" />
                </template>
                <!-- E::출석상태 -->

                <template #[`item.qrcode`]="{ item }">
                    <rental-request-item-qrcode :request="item">
                        <template #activator="{ attrs, on }">
                            <!-- <v-btn color="secondary" outlined icon :disabled="!item.isAttendable" v-bind="attrs" v-on="on">
                        <i class="icon icon-qr" />
                    </v-btn> -->
                            <v-btn color="secondary" outlined icon v-bind="attrs" v-on="on">
                                <i class="icon icon-qr" />
                            </v-btn>
                        </template>
                    </rental-request-item-qrcode>
                </template>

                <template #footer>
                    <div class="v-pagination-wrap">
                        <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="(page) => $router.push({ query: { page } })" />
                    </div>
                </template>
            </v-data-table>
        </page-section>
        <v-fade-transition>
            <v-overlay v-show="loading" color="white">
                <v-progress-circular color="primary" size="100" width="5" indeterminate />
            </v-overlay>
        </v-fade-transition>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { RENTAL_SCHEDULE_STATES, RENTAL_SUBJECT_TYPES, RENTAL_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import RentalRequestCancelBtn from "@/components/client/rental/requests/rental-request-cancel-btn.vue";
import RentalRequestItemQrcode from "@/components/client/rental/requests/rental-request-item-qrcode.vue";
import RentalRequestItemAttendance from "@/components/client/rental/requests/rental-request-item-attendance.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
        RentalRequestCancelBtn,
        RentalRequestItemQrcode,
        RentalRequestItemAttendance,
    },
    props: {
        subjectType: { type: String, default: RENTAL_SUBJECT_TYPES.EQUIPMENTS.value },
    },
    data: () => ({
        limit: 10,

        summary: { totalCount: 0 },
        requests: [],

        RENTAL_SCHEDULE_STATES,

        loading: false,
    }),
    computed: {
        title() {
            switch (this.subjectType) {
                case RENTAL_SUBJECT_TYPES.EQUIPMENTS.value: {
                    return "메이커스 장비 예약 현황";
                }
                case RENTAL_SUBJECT_TYPES.FACILITIES.value: {
                    return "시설 및 공간 예약 현황";
                }
                default: {
                    return undefined;
                }
            }
        },
        items() {
            return this.requests.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index, isDone: this.$dayjs().isAfter(item.datetime) }));
        },
        headers() {
            let title;
            switch (this.subjectType) {
                case RENTAL_SUBJECT_TYPES.EQUIPMENTS.value: {
                    title = "장비 명";
                    break;
                }
                case RENTAL_SUBJECT_TYPES.FACILITIES.value: {
                    title = "시설 및 공간 명";
                    break;
                }
            }

            let headers = [
                { text: title, value: "subject.name", align: "left" },
                { text: "예약일", value: "date", width: "16%", formatter: (value) => (value ? this.$dayjs(value).format("YYYY.MM.DD (dddd)") : "-") },
                {
                    text: "예약시간",
                    value: "times",
                    width: "14%",
                    formatter: function (value = []) {
                        switch (this.rentalType) {
                            case RENTAL_TYPES.BY_DATE.value: {
                                return "종일";
                            }
                            case RENTAL_TYPES.BY_HOUR.value: {
                                return [...(value ?? [])]
                                    ?.sort?.((a, b) => a - b)
                                    ?.map?.((hour) => dayjs().hour(hour).minute(0).format("HH:mm"))
                                    ?.join?.("\n");
                            }
                            case RENTAL_TYPES.BY_TIME.value: {
                                return value?.join?.("\n");
                            }
                        }
                    },
                    cellClass: "white-space-pre-line",
                },
                { text: "신청상태", value: "state", width: "12%", formatter: (value) => RENTAL_SCHEDULE_STATES[value]?.text || value || "-" },
                { text: "취소", value: "cancel", width: "12%" },
                { text: "출석", value: "attendance", width: "10%" },
                { text: "QR", value: "qrcode", width: "10%" },
            ].map((item) => ({ ...item, align: item.align ?? "center" }));

            return headers;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            const { ...query } = this.$route.query;

            delete query.page;
            query.subjectType = this.subjectType;

            return { ...query };
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        params() {
            this.search();
        },
        subjectType() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, params } = this;
                let { summary, requests } = await api.v1.me.rental.requests.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.requests = requests;

                let { subjectType } = this;

                this.requests = await Promise.all(requests.map(async (request) => ({ ...request, attendance: (await api.v1.me.rental.requests.attendance.get({ _request: request._id, params: { subjectType } }))?.attendance })));
            } finally {
                this.loading = false;
            }
        },
        updateItem(item) {
            let index = this.requests.findIndex(({ _id }) => _id == item._id);
            if (index > -1) {
                this.requests.splice(index, 1, item);
            } else {
                this.requests.splice(0, 0, item);
            }
        },
        itemClass(item) {
            if (item.state == RENTAL_SCHEDULE_STATES.CANCELED.value) return "disabled";
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .white-space-pre-line {
    white-space: pre-line;
}
</style>