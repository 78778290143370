var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mt-10",
    attrs: {
      "color": "grey lighten-5",
      "flat": "",
      "rounded": "xs"
    }
  }, [_c('div', {
    staticClass: "pa-16"
  }, [_c('v-btn', {
    staticClass: "v-btn--underline",
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v("보험증서 샘플")]), _c('p', {
    staticClass: "page-text page-text--sm grey--text mt-8"
  }, [_vm._v(" 예시 파일을 확인하시어 보험계약 내용이 유효한 상해보장보험증서로 업로드하시기 바랍니다. "), _c('br'), _vm._v(" * 보험계약내용 : 가입기간이 장비 사용일 기준 유효하고 상해를 보장하는 내용의 보험 "), _c('br'), _vm._v(" * 보험계약내용이 장비 사용일 기준 유효하지 않을 경우 장비예약이 취소될 수 있습니다. ")])], 1)]), _c('v-dialog', {
    attrs: {
      "width": "100%",
      "max-width": "800",
      "content-class": "v-dialog--primary"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-sheet', {
    staticClass: "v-dialog--primary__head"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "icon icon-close"
  })])], 1), _c('v-sheet', {
    staticClass: "v-dialog--primary__body"
  }, [_c('h4', {
    staticClass: "tit mb-16 mb-md-20"
  }, [_vm._v("보험증서 샘플")]), _c('v-divider', {
    staticClass: "grey darken-4 mb-16 mb-md-20",
    staticStyle: {
      "border-top-width": "2px"
    }
  }), _c('v-img', {
    staticClass: "border w-100",
    attrs: {
      "src": "/images/sub/join/cert-example.jpg",
      "max-width": "846",
      "aspect-ratio": 846 / 514
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }