var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "rounded-sm pa-20 pa-md-40 mb-18 mb-md-24",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "reservation-avatar",
    attrs: {
      "src": "/images/icon/icon-avatar.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "page-text font-weight-medium"
  }, [_c('span', [_vm._v("홍길동")]), _c('span', [_vm._v("님 안녕하세요.")])])])], 1)], 1), _c('v-simple-table', {
    staticClass: "v-data-table--no-hover",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("시설 및 공간 명")]), _c('th', [_vm._v("예약일")]), _c('th', [_vm._v("예약 시간")]), _c('th', [_vm._v("신청")]), _c('th', [_vm._v("상태")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-start"
        }, [_vm._v("시설 및 공간명이 들어갑니다.")]), _c('td', [_vm._v("2023.04.05 (수요일)")]), _c('td', [_vm._v("10:00 ~ 11:00")]), _c('td', [[_c('v-btn', {
          attrs: {
            "small": "",
            "color": "primary",
            "rounded": "pill"
          }
        }, [_vm._v("신청취소")])], [_c('div', {
          staticClass: "red--text font-weight-medium"
        }, [_vm._v("취소완료")])]], 2), _c('td', [_c('div', {
          staticClass: "font-weight-bold"
        }, [[_c('span', {
          staticClass: "grey--text"
        }, [_vm._v("대기")])], [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("승인")])]], 2)])])])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 20
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }