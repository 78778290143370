var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "프로그램 신청 현황"
    }
  }, [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default table--primary--large",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.program.name`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('router-link', {
          attrs: {
            "to": `/program/view/${item._program}`
          }
        }, [_vm._v(_vm._s(value))])];
      }
    }, {
      key: `item.cancel`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('program-application-cancel-btn', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          }
        })];
      }
    }, {
      key: `item.attend`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.state == _vm.PROGRAM_APPLICATION_STATES.APPROVED.value ? _c('v-chip', {
          attrs: {
            "to": `/mypage/programs/${item._program}`,
            "color": "orange"
          }
        }, [_c('span', {
          staticClass: "white--text font-size-12"
        }, [_vm._v("출석확인")])]) : _vm._e()];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "v-pagination-wrap"
        }, [_c('v-pagination', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": "11"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: {
                  page
                }
              });
            }
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    itemClass: _vm.itemClass
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }