<template>
    <v-data-table v-bind="{ items, headers, loading }" hide-default-footer disable-filtering disable-sort class="v-data-table--default">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

        <template #[`item.time`]="{ item }"> {{ item.timeStart }} ~ {{ item.timeEnd }} </template>
        <template #[`item.attendance`]="{ value }">
            <p class="font-weight-bold">
                <template v-if="value?.isAttended === null">
                    <span class="grey--text">대기</span>
                </template>
                <template v-if="value?.isAttended === true">
                    <span class="primary--text">출석</span>
                </template>
                <template v-if="value?.isAttended === false">
                    <span class="error--text">불참</span>
                </template>
            </p>
        </template>

        <template #[`item.qrcode`]="{ item }">
            <program-unit-item-qrcode v-bind="{ program }" :unit="item">
                <template #activator="{ attrs, on }">
                    <v-btn color="secondary" outlined icon :disabled="!item.isAttendable" v-bind="attrs" v-on="on">
                        <i class="icon icon-qr" />
                    </v-btn>
                </template>
            </program-unit-item-qrcode>
        </template>

        <user-qrcode />
    </v-data-table>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { initProgram, initProgramUnit } from "@/assets/variables";

import ProgramUnitItemQrcode from "./program-unit-item-qrcode.vue";

let headers = [
    { text: "회차", value: "number", formatter: (value) => `${value}회차` },
    { text: "날짜", value: "date", formatter: (value) => dayjs(value).format("YYYY.MM.DD (dddd)") },
    { text: "시간", value: "time" },
    { text: "출석", value: "attendance" },
    { text: "QR", value: "qrcode" },
].map((item) => ({ ...item, align: "center" }));

export default {
    components: {
        ProgramUnitItemQrcode,
    },
    props: {
        program: { type: Object, default: initProgram },
    },
    data: () => ({
        attendances: [],

        headers,

        loading: false,
    }),
    computed: {
        items() {
            return (this.program?.units || []).map((item, index) => {
                return initProgramUnit({
                    ...item,
                    number: index + 1,
                    attendance: this.attendances.find(({ _unit }) => _unit == item._id),
                    isAttendable: this.$dayjs().isSame(item.date, "date"),
                });
            });
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "program._id"() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (!this.program._id) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                this.attendances = (await api.v1.me.programs.attendances.gets({ _program: this.program._id }))?.attendances;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>