<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <div role="table" class="board-table board-table--view">
                <div role="rowgroup">
                    <div role="row">
                        <div role="columnheader">{{ question?.subject }}</div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <ul class="board-view-data">
                                <li>
                                    작성일 :
                                    {{ question?.createdAt?.toDate() }}
                                </li>
                                <li>상태 : 답변 {{ question?.reply ? "완료" : "대기" }}</li>
                            </ul>
                        </div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <div class="board-content">
                                <!-- <div v-html=".content.replace(/\n/g, '<br>')"></div> -->
                                <!-- <v-textarea v-model="question.content" disabled /> -->
                                <div class="white-space-pre-line">{{ question?.content }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="question?.reply" role="row" class="grey lighten-5">
                        <div role="cell">
                            <v-row class="row--xxs">
                                <v-col cols="auto">
                                    <v-img max-width="16" src="/images/icon/icon-indicator.svg" />
                                </v-col>
                                <v-col>
                                    <!-- <v-textarea v-model="question.reply" disabled></v-textarea> -->
                                    <div class="white-space-pre-line">{{ question?.reply }}</div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-24">
                <v-row class="row--xxs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                            <span class="grey--text font-size-14">리스트</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initQuestions } from "@/assets/variables";
import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
    },
    data: () => ({
        question: initQuestions(),
    }),
    mounted() {
        this.init();
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        _inquiry() {
            return this.$route.params._inquiry;
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                await this.search();
            } finally {
                this.loading = false;
            }
        },
        async search() {
            let { question } = await api.v1.center.questions.get({ _id: this._inquiry });
            this.question = initQuestions(question);
        },
    },
};
</script>

<style lang="scss" scoped>
.white-space-pre-line {
    white-space: pre-line;
}
.reservation-avatar {
    width: 60px;
}
.v-data-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 768px) {
    .reservation-avatar {
        width: 80px;
    }
    .v-data-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
