<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <v-sheet color="grey lighten-5" class="rounded-sm pa-20 pa-md-40 mb-18 mb-md-24">
                <v-row align="center">
                    <v-col cols="auto">
                        <v-img src="/images/icon/icon-avatar.svg" class="reservation-avatar" />
                    </v-col>
                    <v-col>
                        <div class="page-text font-weight-medium">
                            <span>{{ user?.nickname }}</span>
                            <span>님 안녕하세요.</span>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="mb-18 mb-md-24">
                <board-list-search />
            </div>
            <v-simple-table>
                <template #default>
                    <thead>
                        <tr>
                            <th>제목</th>
                            <th>작성일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(question, index) in questions" :key="index" @click="$router.push(`${$route.path}/${question._id}`)">
                            <td class="text-start">
                                {{ question?.subject }}
                            </td>
                            <td>{{ $dayjs(question?.createdAt).format("YYYY-MM-DD") }}</td>
                            <td>
                                답변
                                <template v-if="!question?.reply">대기</template>
                                <template v-else>완료</template>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div class="d-flex justify-end">
                <v-btn outlined height="40" color="grey lighten-3" class="px-18 my-18 my-md-24" to="./inquiry/write">
                    <span class="grey--text">문의하기</span>
                </v-btn>
            </div>
            <div class="v-btn--group">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import BoardListSearch from "@/components/client/board/board-list-search.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    components: {
        ClientPage,
        BoardListSearch,
        SubVisual,
        PageSection,
        MypageNavigation,
    },
    data: () => ({
        questions: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    mounted() {
        this.init();
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        user() {
            return this.$store.state?.user;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        params() {
            let { ...query } = this.$route.query;

            return query;
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                await this.search();
            } finally {
                this.loading = false;
            }
        },
        async search() {
            const { skip, limit, params } = this;
            let { summary, questions } = await api.v1.center.questions.gets({
                headers: { skip, limit },
                params,
            });

            this.questions = questions;
            this.summary = summary;
        },
    },
    watch: {
        page() {
            this.init();
        },
        params() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
.reservation-avatar {
    width: 60px;
}
.v-data-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 768px) {
    .reservation-avatar {
        width: 80px;
    }
    .v-data-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
