var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._attendance ? _c('dialogue', {
    attrs: {
      "max-width": "800"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "rounded": "",
      "color": "secondary lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "530"
    }
  }, [_c('div', {
    staticClass: "text-center mb-20 mb-md-40"
  }, [_c('h4', {
    staticClass: "tit mb-12 mb-md-16"
  }, [_vm._v("QR코드 발행이 완료되었습니다.")]), _c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("입실 및 퇴실 시 하단의 QR코드를 리더기에 찍어주세요.")])]), _c('v-card', {
    staticClass: "mb-20 mb-md-40",
    attrs: {
      "flat": "",
      "rounded": "",
      "color": "white"
    }
  }, [_c('v-layout', {
    staticClass: "pa-20 pa-md-30 pa-lg-40",
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('qrcode-vue', {
    staticClass: "d-flex justify-center",
    attrs: {
      "value": _vm.qrcodeData,
      "size": _vm.$vuetify.breakpoint.mdAndUp ? '450' : '145'
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mb-20 mb-md-40",
    attrs: {
      "flat": "",
      "rounded": "",
      "color": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('v-sheet', {
    staticClass: "mb-n4 mb-md-n14"
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('info-row-inline', _vm._b({
      key: index,
      staticClass: "mb-md-14"
    }, 'info-row-inline', item, false))];
  })], 2)], 1)])], 1)], 1)])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }