<template>
    <dialogue v-model="shows" max-width="800" v-if="_attendance">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card flat rounded color="secondary lighten-5">
            <div class="pa-20 pa-md-30 pa-lg-40">
                <v-sheet color="transparent" max-width="530" class="mx-auto">
                    <div class="text-center mb-20 mb-md-40">
                        <h4 class="tit mb-12 mb-md-16">QR코드 발행이 완료되었습니다.</h4>
                        <p class="page-text page-text--lg grey--text">입실 및 퇴실 시 하단의 QR코드를 리더기에 찍어주세요.</p>
                    </div>
                    <v-card flat rounded color="white" class="mb-20 mb-md-40">
                        <v-layout justify-center align-center class="pa-20 pa-md-30 pa-lg-40">
                            <qrcode-vue :value="qrcodeData" :size="$vuetify.breakpoint.mdAndUp ? '450' : '145'" class="d-flex justify-center" />
                        </v-layout>
                    </v-card>
                    <v-card flat rounded color="white" class="mb-20 mb-md-40">
                        <div class="pa-20 pa-md-30 pa-lg-40">
                            <v-sheet class="mb-n4 mb-md-n14">
                                <template v-for="(item, index) in items">
                                    <info-row-inline class="mb-md-14" v-bind="item" :key="index" />
                                </template>
                            </v-sheet>
                        </div>
                    </v-card>
                </v-sheet>
            </div>
        </v-card>
    </dialogue>
</template>

<script>
import { COMMON_ATTENDANCE_TYPES, initProgram, initProgramUnit } from "@/assets/variables";

import QrcodeVue from "qrcode.vue";

import Dialogue from "@/components/client/dumb/dialogue.vue";
import InfoRowInline from "@/components/client/sub/info-row-inline.vue";

export default {
    components: {
        QrcodeVue,

        Dialogue,
        InfoRowInline,
    },
    props: {
        program: { type: Object, default: initProgram },
        unit: { type: Object, default: initProgramUnit },
    },
    data: () => ({
        shows: false,
    }),
    computed: {
        _attendance() {
            return this.unit?.attendance?._id;
        },
        items() {
            return [
                { title: "프로그램명", info: this.program.name },
                { title: "교육일", info: `${this.unit.date?.replaceAll?.("-", ".")} (${this.unit?.day})` },
                { title: "교육시간", info: `${this.unit.timeStart} ~ ${this.unit.timeEnd}` },
            ];
        },
        qrcodeData() {
            const { _attendance } = this;
            return _attendance;
        },
    },
};
</script>

<style>
</style>