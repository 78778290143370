var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-tab"
  }, [_c('v-container', [_c('v-tabs', {
    attrs: {
      "height": _vm.$vuetify.breakpoint.mdAndUp ? '64' : '52',
      "hide-slider": ""
    }
  }, [_vm._l(_vm.items, function (_ref, index) {
    var text = _ref.text,
      to = _ref.to;
    return [_c('v-tab', _vm._b({
      key: index
    }, 'v-tab', {
      to
    }, false), [_vm._v(" " + _vm._s(text) + " ")])];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }