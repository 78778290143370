<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <v-sheet color="grey lighten-5" class="rounded-sm pa-20 pa-md-40 mb-18 mb-md-24">
                <v-row align="center">
                    <v-col cols="auto">
                        <v-img src="/images/icon/icon-avatar.svg" class="reservation-avatar" />
                    </v-col>
                    <v-col>
                        <div class="page-text font-weight-medium">
                            <span>홍길동</span>
                            <span>님 안녕하세요.</span>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-simple-table class="v-data-table--no-hover">
                <template #default>
                    <thead>
                        <tr>
                            <th>시설 및 공간 명</th>
                            <th>예약일</th>
                            <th>예약 시간</th>
                            <th>신청</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-start">시설 및 공간명이 들어갑니다.</td>
                            <td>2023.04.05 (수요일)</td>
                            <td>10:00 ~ 11:00</td>
                            <td>
                                <template>
                                    <v-btn small color="primary" rounded="pill">신청취소</v-btn>
                                </template>
                                <template>
                                    <div class="red--text font-weight-medium">취소완료</div>
                                </template>
                            </td>
                            <td>
                                <div class="font-weight-bold">
                                    <template>
                                        <span class="grey--text">대기</span>
                                    </template>
                                    <template>
                                        <span class="primary--text">승인</span>
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div class="v-btn--group">
                <v-pagination v-model="page" :length="pageCount" :total-visible="20" @input="search"></v-pagination>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
    },
    data: () => ({}),
    mounted() {
        this.init();
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                var { user } = await api.v1.me.get();

                if (user?.type == USER_TYPES.INDIVIDUAL) {
                    this.form = initUserIndividual(user);
                }
                if (user?.type == USER_TYPES.ORGANIZATION) {
                    this.form = initUserOrganization(user);
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.reservation-avatar {
    width: 60px;
}
.v-data-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 768px) {
    .reservation-avatar {
        width: 80px;
    }
    .v-data-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
