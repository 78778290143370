<template>
    <client-page class="wrap wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지"></sub-visual>
        <mypage-navigation />

        <page-section title="신청 프로그램 정보" class="page-section--first">
            <program-view-card v-bind="{ program }" hideApplyButton />
        </page-section>
        <page-section title="신청 프로그램 일정" class="page-section--last">
            <program-unit-list v-bind="{ program }" />

            <div class="board-buttons d-flex justify-end mt-20 mt-lg-30">
                <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                    <span class="grey--text font-size-14">리스트</span>
                </v-btn>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initProgram } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

import ProgramViewCard from "@/components/client/programs/view/program-view-card.vue";
import ProgramUnitList from "@/components/client/programs/units/program-unit-list.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,

        ProgramViewCard,
        ProgramUnitList,
    },
    props: {
        _program: { type: String, default: null },
    },
    data: () => ({
        program: initProgram(),
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { program } = await api.v1.programs.get({ _id: this._program });
            this.program = program;
        },
    },
};
</script>
