var render = function render(){
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "rounded-sm pa-20 pa-md-40 mb-18 mb-md-24",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "reservation-avatar",
    attrs: {
      "src": "/images/icon/icon-avatar.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "page-text font-weight-medium"
  }, [_c('span', [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.nickname))]), _c('span', [_vm._v("님 안녕하세요.")])])])], 1)], 1), _c('div', {
    staticClass: "mb-18 mb-md-24"
  }, [_c('board-list-search')], 1), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("제목")]), _c('th', [_vm._v("작성일")]), _c('th', [_vm._v("상태")])])]), _c('tbody', _vm._l(_vm.questions, function (question, index) {
          return _c('tr', {
            key: index,
            on: {
              "click": function ($event) {
                return _vm.$router.push(`${_vm.$route.path}/${question._id}`);
              }
            }
          }, [_c('td', {
            staticClass: "text-start"
          }, [_vm._v(" " + _vm._s(question === null || question === void 0 ? void 0 : question.subject) + " ")]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(question === null || question === void 0 ? void 0 : question.createdAt).format("YYYY-MM-DD")))]), _c('td', [_vm._v(" 답변 "), !(question !== null && question !== void 0 && question.reply) ? [_vm._v("대기")] : [_vm._v("완료")]], 2)]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('v-btn', {
    staticClass: "px-18 my-18 my-md-24",
    attrs: {
      "outlined": "",
      "height": "40",
      "color": "grey lighten-3",
      "to": "./inquiry/write"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("문의하기")])])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }