var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 mb-16 mb-md-20"
  }, [_vm._v("비밀번호 재설정")]), _c('table-primary', [_c('table-row', {
    attrs: {
      "title": "비밀번호"
    }
  }, [_c('v-password-field', {
    attrs: {
      "rules": _vm.rules.password,
      "placeholder": "새로운 비밀번호를 입력하세요.",
      "outlined": ""
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "비밀번호 확인"
    }
  }, [_c('v-password-confirm-field', {
    attrs: {
      "type": "password",
      "password": _vm.password,
      "placeholder": "비밀번호를 재입력 하세요.",
      "outlined": ""
    }
  })], 1)], 1), _c('password-rule'), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.recovery
    }
  }, [_c('span', [_vm._v("수정 완료")])])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }