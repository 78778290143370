<template>
    <p class="font-weight-bold">
        <template v-if="isAttended == null">
            <span class="grey--text">대기</span>
        </template>
        <template v-if="isAttended == true">
            <span class="primary--text">출석</span>
        </template>
        <template v-if="isAttended == false">
            <span class="error--text">불참</span>
        </template>
    </p>
</template>

<script>
import { initCommonAttendance } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, default: initCommonAttendance },
    },
    computed: {
        isAttended() {
            return this.value?.isAttended;
        },
    },
};
</script>

<style>
</style>