var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "mypage-home-background",
    attrs: {
      "color": "grey lighten-5"
    }
  }), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "rounded-sm pa-18 pa-md-24",
      attrs: {
        "to": item.link,
        "outlined": "",
        "flat": ""
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('div', {
      staticClass: "tit tit--xs font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "d-flex mx-n4 py-12 py-md-16"
    }, [_vm._l(3, function (n) {
      return [_c('div', {
        key: n,
        staticClass: "px-4"
      }, [_c('v-sheet', {
        attrs: {
          "width": "4",
          "height": "4",
          "rounded": "circle",
          "color": "primary"
        }
      })], 1)];
    })], 2), _c('div', {
      staticClass: "page-text grey--text",
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "primary",
      attrs: {
        "icon": "",
        "width": "56",
        "height": "56"
      }
    }, [_c('v-img', {
      attrs: {
        "src": "/images/icon/icon-white-right.svg",
        "max-width": "8"
      }
    })], 1)], 1)], 1)], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }