<template>
    <client-page>
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <div role="table" class="board-table board-table--view">
                <div role="rowgroup">
                    <div role="row">
                        <div role="columnheader" class="py-16">
                            <v-row align="center">
                                <v-col cols="1" class="text-center">제목</v-col>
                                <v-col><v-text-field v-model="form.subject" hide-details></v-text-field></v-col>
                            </v-row>
                        </div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <div class="board-content">
                                <!-- <naver-smarteditor /> -->
                                <v-textarea v-model="form.content" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-24">
                <v-row class="row--xxs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                            <span class="grey--text font-size-14">리스트</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn outlined color="tertiary" class="h-40px" @click="save">
                            <span class="font-size-14">작성하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initQuestions } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        NaverSmarteditor,
        MypageNavigation,
    },
    data: () => ({
        form: initQuestions(),
    }),
    methods: {
        async save() {
            try {
                if (!this.validates()) return;
                let { ...form } = this.form;

                let _user = this.$store.state.payload._user;

                if (!_user) throw new Error("로그인 후 이용할 수 있습니다.");

                await api.v1.center.questions.post({ ...form, _user });

                alert("문의 접수가 완료되었습니다.");

                this.$router.go(-1);
            } catch (error) {
                this.$handleError(error);
            }
        },
        validates() {
            if (!this.form.subject) throw new Error("문의 제목을 작성해주세요.");
            if (!this.form.content) throw new Error("문의 내용을 작성해주세요.");

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
}
</style>
