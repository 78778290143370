<template>
    <v-card flat v-bind="{ loading }">
        <v-card rounded flat class="info-card text-center page-text" style="overflow: hidden">
            <v-row no-gutters>
                <v-col v-for="item in info" :key="item.title" cols="6" md="" class="info-card__col">
                    <div class="info-card__content secondary lighten-5">
                        <p class="font-weight-medium">{{ item.title }}</p>
                    </div>
                    <div class="info-card__content">
                        <p class="grey--text">{{ item.info }}</p>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <p class="page-text grey--text text--lighten-1 line-height-15 mt-10">* 패널티는 불참일 다음 날부터 적용되며 패널티 적용 기간 내 모든 예약 내용은 자동 취소됩니다.</p>
    </v-card>
</template>

<script>
import api from "@/api";
export default {
    data: () => ({
        ban: null,

        loading: false,
    }),
    computed: {
        info() {
            let period = "";
            if (this.ban?.startsAt) period += this.ban?.startsAt?.toDate?.();
            if (this.ban?.startsAt || this.ban?.endsAt) period += " ~ ";
            if (this.ban?.endsAt) period += this.ban?.endsAt?.toDate?.();
            period = period.trim();

            const items = [
                { title: "1회 불참", info: "7일 사용금지" },
                { title: "2회 불참", info: "15일 사용금지" },
                { title: "3회 불참 이상", info: "30일 사용금지" },
                { title: "총 불참 횟수", info: this.ban?.count || 0 },
                { title: "패널티 기간", info: period || "-" },
                { title: "패널티 사유", info: this.ban?.desc || "-" },
            ];

            return items;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this.$store.state.payload?._user) return;

            if (this.loading) return;
            else this.loading = true;
            try {
                const { bans } = await api.v1.me.bans.gets({ headers: { skip: 0, limit: 1 } });

                this.ban = bans[0] || null;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.info-card {
    border: 1px solid var(--v-grey-lighten4);
    &__content {
        min-height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .info-card {
        border: 0;
        &__content {
            min-height: 56px;
            padding: 12px 20px;
        }
        &__col {
            border-bottom: 1px solid var(--v-grey-lighten4);
        }
    }
    .row {
        .info-card__col:first-child {
            border-bottom-left-radius: 24px;
            border-left: 1px solid var(--v-grey-lighten4);
        }
        .info-card__col:last-child {
            border-bottom-right-radius: 24px;
            border-right: 1px solid var(--v-grey-lighten4);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>