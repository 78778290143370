var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.time`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.timeStart) + " ~ " + _vm._s(item.timeEnd) + " ")];
      }
    }, {
      key: `item.attendance`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('p', {
          staticClass: "font-weight-bold"
        }, [(value === null || value === void 0 ? void 0 : value.isAttended) === null ? [_c('span', {
          staticClass: "grey--text"
        }, [_vm._v("대기")])] : _vm._e(), (value === null || value === void 0 ? void 0 : value.isAttended) === true ? [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("출석")])] : _vm._e(), (value === null || value === void 0 ? void 0 : value.isAttended) === false ? [_c('span', {
          staticClass: "error--text"
        }, [_vm._v("불참")])] : _vm._e()], 2)];
      }
    }, {
      key: `item.qrcode`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('program-unit-item-qrcode', _vm._b({
          attrs: {
            "unit": item
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var attrs = _ref5.attrs,
                on = _ref5.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "outlined": "",
                  "icon": "",
                  "disabled": !item.isAttendable
                }
              }, 'v-btn', attrs, false), on), [_c('i', {
                staticClass: "icon icon-qr"
              })])];
            }
          }], null, true)
        }, 'program-unit-item-qrcode', {
          program: _vm.program
        }, false))];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false), [_c('user-qrcode')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }