<template>
    <div>
        <template v-if="isCanceled">
            <span>취소완료</span>
        </template>
        <template v-else-if="isCancelable">
            <v-chip dark color="secondary" @click="cancel"><span class="font-size-12">신청취소</span></v-chip>
        </template>
        <template v-else>
            <v-chip color="grey lighten-4"><span class="grey--text text--lighten-1 font-size-12">신청취소</span></v-chip>
        </template>
    </div>
</template>

<script>
import api from "@/api";
import { initProgramApplication, PROGRAM_APPLICATION_STATES } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initProgramApplication },
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        isCanceled() {
            return this.value.state == PROGRAM_APPLICATION_STATES.CANCELED.value;
        },
        isCancelable() {
            if (this.value.state == PROGRAM_APPLICATION_STATES.CANCELED.value) return false;
            if (this.value.state == PROGRAM_APPLICATION_STATES.REJECTED.value) return false;
            if (this.$dayjs().isAfter(this.value.program.application.endsAt, "date")) return false;
            return true;
        },
    },
    methods: {
        async cancel() {
            const { program, _program, _id } = this.value;

            let go = confirm(`아래 프로그램에 대한 신청을 취소하시겠습니까?\n\n프로그램명: ${program?.name}`);
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { application } = await api.v1.me.programs.applications.putCancel({ _program, _id });
                this.$emit("input", application);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
