<template>
    <client-page class="wrap wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지"></sub-visual>
        <mypage-navigation />

        <page-section title="프로그램 신청 현황" class="page-section--first page-section--last">
            <v-data-table v-bind="{ items, headers, itemClass }" hide-default-footer disable-filtering disable-sort class="v-data-table--default table--primary--large">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                <template #[`item.program.name`]="{ item, value }">
                    <router-link :to="`/program/view/${item._program}`">{{ value }}</router-link>
                </template>

                <template #[`item.cancel`]="{ item }">
                    <program-application-cancel-btn :value="item" @input="updateItem" />
                </template>

                <template #[`item.attend`]="{ item }">
                    <v-chip v-if="item.state == PROGRAM_APPLICATION_STATES.APPROVED.value" :to="`/mypage/programs/${item._program}`" color="orange"><span class="white--text font-size-12">출석확인</span></v-chip>
                </template>

                <template #footer>
                    <div class="v-pagination-wrap">
                        <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="(page) => $router.push({ query: { page } })" />
                    </div>
                </template>
            </v-data-table>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { PROGRAM_APPLICATION_STATES, PROGRAM_STATES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import ProgramApplicationCancelBtn from "@/components/client/programs/applications/program-application-cancel-btn.vue";

let headers = [
    { text: "유형", value: "program.category.name", width: "12%" },
    { text: "프로그램명", value: "program.name", width: "auto" },
    { text: "교육상태", value: "program.state", width: "10%", formatter: (key) => PROGRAM_STATES[key]?.text },
    { text: "신청상태", value: "state", width: "10%", formatter: (key) => PROGRAM_APPLICATION_STATES[key]?.text },
    { text: "취소", value: "cancel", width: "10%" },
    { text: "출석", value: "attend", width: "10%" },
].map((item) => ({ ...item, align: item.align ?? "center" }));

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
        ProgramApplicationCancelBtn,
    },
    data: () => ({
        limit: 10,

        summary: { totalCount: 0 },
        applications: [],

        headers,
        PROGRAM_APPLICATION_STATES,
    }),
    computed: {
        items() {
            return this.applications.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
        },
        async search() {
            let { skip, limit } = this;
            let { summary, applications } = await api.v1.me.programs.applications.gets({ _program: undefined, headers: { skip, limit } });

            this.summary = summary;
            this.applications = applications;
        },
        updateItem(item) {
            let index = this.applications.findIndex(({ _id }) => _id == item._id);
            if (index > -1) {
                this.applications.splice(index, 1, item);
            } else {
                this.applications.splice(0, 0, item);
            }
        },
        itemClass(item) {
            if (item.state == PROGRAM_APPLICATION_STATES.CANCELED.value) return "disabled";
        },
    },
};
</script>
