var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "rounded-xs pa-16 pa-md-20",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-28 mb-10 mb-md-12"
  }, [_vm._v("제4장 서비스 제공 및 이용")]), _c('v-row', {
    staticClass: "row--xxs page-text"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "mt-8",
    attrs: {
      "width": "4",
      "height": "4",
      "color": "grey darken-4",
      "rounded": "circle"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "grey--text text--darken-4 mb-10"
  }, [_vm._v("제11조 서비스 이용 해지")]), _c('div', {
    staticClass: "grey--text"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1. ")]), _c('v-col', [_vm._v("회원이 사이트와의 이용계약을 해지하고자 하는 경우에는 회원 본인이 온라인을 통하여 등록해지 신청을 하여야 합니다. 한편, 사이트 이용 해지와 별개로 사이트에 대한 이용계약 해지는 별도로 하셔야 합니다.")])], 1), _c('br'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2. ")]), _c('v-col', [_vm._v("해지 신청과 동시에 사이트가 제공하는 사이트 관련 프로그램이 회원 관리 화면에서 자동적으로 삭제됨으로 운영자는 더 이상 해지신청자의 정보를 볼 수 없습니다.")])], 1)], 1)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "label": "해당 내용을 모두 확인하였으며, 탈퇴에 동의합니다."
    },
    model: {
      value: _vm.agreement,
      callback: function ($$v) {
        _vm.agreement = $$v;
      },
      expression: "agreement"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "dark": "",
      "rounded": "",
      "color": "grey"
    },
    on: {
      "click": _vm.withdrawl
    }
  }, [_vm._v("탈퇴 진행")])], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }