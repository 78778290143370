var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card', {
    staticClass: "info-card text-center page-text",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.info, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "info-card__col",
      attrs: {
        "cols": "6",
        "md": ""
      }
    }, [_c('div', {
      staticClass: "info-card__content secondary lighten-5"
    }, [_c('p', {
      staticClass: "font-weight-medium"
    }, [_vm._v(_vm._s(item.title))])]), _c('div', {
      staticClass: "info-card__content"
    }, [_c('p', {
      staticClass: "grey--text"
    }, [_vm._v(_vm._s(item.info))])])]);
  }), 1)], 1), _c('p', {
    staticClass: "page-text grey--text text--lighten-1 line-height-15 mt-10"
  }, [_vm._v("* 패널티는 불참일 다음 날부터 적용되며 패널티 적용 기간 내 모든 예약 내용은 자동 취소됩니다.")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }