var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-10",
    attrs: {
      "color": "grey lighten-5",
      "flat": "",
      "rounded": "xs"
    }
  }, [_c('div', {
    staticClass: "pa-16"
  }, [_c('v-btn', {
    staticClass: "v-btn--underline",
    attrs: {
      "href": "/file/equipment-agreement.hwp",
      "download": "용산시제품제작소_3D프린터_장비이용수칙동의서.hwp",
      "text": "",
      "color": "secondary"
    }
  }, [_vm._v("장비이용동의서 다운로드(3D프린터)")]), _c('p', {
    staticClass: "page-text page-text--sm grey--text mt-8"
  }, [_vm._v(" 장비이용수칙동의서는 메이커스페이스 장비 중 고위험 장비에 해당하지않는 3D프린터만을 사용하는 회원의 경우 상해보험가입증서를 대체하여 제출합니다. ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }