<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지"></sub-visual>
        <mypage-navigation />

        <page-section title="불참 기록" class="page-section--first page-section--last">
            <!-- 불참기록 -->
            <penalty-info class="mb-20 mb-md-40" />

            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort class="v-data-table--default">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <!-- S::출석상태 -->
                <template #[`item.attendance.isAttended`]="{ value }">
                    <p class="font-weight-bold">
                        <template v-if="value === null">
                            <span class="grey--text">대기</span>
                        </template>
                        <template v-if="value === true">
                            <span class="primary--text">출석</span>
                        </template>
                        <template v-if="value === false">
                            <span class="error--text">불참</span>
                        </template>
                    </p>
                </template>
                <!-- E::출석상태 -->

                <template #footer>
                    <div class="v-pagination-wrap">
                        <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="(page) => $router.push({ query: { page } })" />
                    </div>
                    <!-- <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { page } })" /> -->
                </template>
            </v-data-table>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import PenaltyInfo from "@/components/client/mypage/penalty-info.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";

let headers = [
    { text: "장비명", value: "subject.name", width: "34%", align: "left" },
    { text: "예약일", value: "attendance.date", width: "28%", align: "center", formatter: (value) => (value ? dayjs(value).format("YYYY.MM.DD (dddd)") : "-") },
    { text: "예약 시간", value: "attendance.times", width: "28%", align: "center", formatter: (value) => (value?.length ? value.join("\n") : "종일") },
    { text: "출석", value: "attendance.isAttended", width: "10%", align: "center" },
];

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        PageSection,
        PenaltyInfo,
        PaginationComponent,
    },
    data: () => ({
        limit: 10,

        summary: { totalCount: 0 },
        bans: [],

        headers,
    }),
    computed: {
        items() {
            return this.bans.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.reroute().then(this.search);
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async reroute() {
            if (!this.$store.state.accessToken) this.$router.push("/login");
        },
        async search() {
            let { skip, limit } = this;
            let { summary, bans } = await api.v1.me.bans.gets({ headers: { skip, limit } });

            this.summary = summary;
            this.bans = bans;
        },
    },
};
</script>
