var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first",
    attrs: {
      "title": "신청 프로그램 정보"
    }
  }, [_c('program-view-card', _vm._b({
    attrs: {
      "hideApplyButton": ""
    }
  }, 'program-view-card', {
    program: _vm.program
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--last",
    attrs: {
      "title": "신청 프로그램 일정"
    }
  }, [_c('program-unit-list', _vm._b({}, 'program-unit-list', {
    program: _vm.program
  }, false)), _c('div', {
    staticClass: "board-buttons d-flex justify-end mt-20 mt-lg-30"
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }