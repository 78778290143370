var render = function render(){
  var _vm$form, _vm$form$birthday;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 mb-16 mb-md-20"
  }, [_vm._v("필수입력 정보")]), _c('table-primary', [_c('table-row', {
    attrs: {
      "title": "아이디"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.username) + " ")]), _c('table-row', {
    attrs: {
      "title": "이름"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.name) + " ")]), _c('table-row', {
    attrs: {
      "title": "닉네임",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.rules.nickname,
      "placeholder": "닉네임을 입력하세요.",
      "outlined": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "성별",
      "pointer": ""
    }
  }, [_c('v-gender-radio-group', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.gender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "연락처",
      "pointer": ""
    }
  }, [_c('v-phone-field', {
    attrs: {
      "hasCertification": "",
      "disabled": ""
    },
    on: {
      "certify": _vm.edit
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "이메일",
      "pointer": ""
    }
  }, [_c('v-email-field', {
    attrs: {
      "rules": _vm.rules.email
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 mb-16 mb-md-20"
  }, [_vm._v("부가입력 정보")]), _c('table-primary', [_c('table-row', {
    attrs: {
      "title": "생년월일"
    }
  }, [_vm._v(" " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$birthday = _vm$form.birthday) === null || _vm$form$birthday === void 0 ? void 0 : _vm$form$birthday.slice(2)) + " ")]), _c('table-row', {
    attrs: {
      "title": "행사 및 관련 소식 <br class='d-none d-md-block' />수신여부"
    }
  }, [_c('v-news-radio-group', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.marketingMethod,
      callback: function ($$v) {
        _vm.$set(_vm.form, "marketingMethod", $$v);
      },
      expression: "form.marketingMethod"
    }
  })], 1), _c('table-row', {
    attrs: {
      "title": "주소"
    }
  }, [_c('v-address-field', {
    attrs: {
      "noRules": true
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xs",
    attrs: {
      "large": "",
      "rounded": "",
      "dark": "",
      "color": "primary",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.save
    }
  }, [_c('span', [_vm._v("수정 완료")])])], 1)], 1)], 1)], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "light": "",
      "color": "white",
      "opacity": "0.2"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "100",
      "width": "5",
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }