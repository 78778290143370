var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg pa-16 px-md-84 py-md-60",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 text-center"
  }, [_vm._v(" 비밀번호 재설정을 위해 "), _c('br'), _vm._v(" 본인인증을 진행해주세요. ")]), _c('v-btn', {
    staticClass: "white--text mt-40 mt-md-48",
    attrs: {
      "block": "",
      "x-large": "",
      "color": "#FF3A4A"
    }
  }, [_vm._v("본인 인증 하기")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }