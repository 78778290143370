<template>
    <v-card color="grey lighten-5" flat rounded="xs" class="mt-10">
        <div class="pa-16">
            <v-btn href="/file/equipment-agreement.hwp" download="용산시제품제작소_3D프린터_장비이용수칙동의서.hwp" text color="secondary" class="v-btn--underline">장비이용동의서 다운로드(3D프린터)</v-btn>
            <p class="page-text page-text--sm grey--text mt-8">
                장비이용수칙동의서는 메이커스페이스 장비 중 고위험 장비에 해당하지않는 3D프린터만을 사용하는 회원의 경우 상해보험가입증서를 대체하여 제출합니다.
            </p>
        </div>
    </v-card>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
</style>