var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "mb-4"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": _vm.align
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg font-weight-medium"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('v-col', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm.info ? _c('p', [_vm._v(_vm._s(_vm.info))]) : _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }