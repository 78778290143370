<template>
    <div>        
        <v-card color="grey lighten-5" flat rounded="xs" class="mt-10">
            <div class="pa-16">
                <v-btn text color="secondary" class="v-btn--underline" @click="open">보험증서 샘플</v-btn>
                <p class="page-text page-text--sm grey--text mt-8">
                    예시 파일을 확인하시어 보험계약 내용이 유효한 상해보장보험증서로 업로드하시기 바랍니다. <br>
                    * 보험계약내용 : 가입기간이 장비 사용일 기준 유효하고 상해를 보장하는 내용의 보험 <br>
                    * 보험계약내용이 장비 사용일 기준 유효하지 않을 경우 장비예약이 취소될 수 있습니다.
                </p>
            </div>
        </v-card>
        <v-dialog v-model="show" width="100%" max-width="800" content-class="v-dialog--primary">
            <v-sheet class="v-dialog--primary__head">
                <v-btn icon text @click="close"><i class="icon icon-close"></i></v-btn>
            </v-sheet>
            <v-sheet class="v-dialog--primary__body">
                <h4 class="tit mb-16 mb-md-20">보험증서 샘플</h4>
                <v-divider class="grey darken-4 mb-16 mb-md-20" style="border-top-width: 2px;"/>
                <v-img src="/images/sub/join/cert-example.jpg" max-width="846" :aspect-ratio="846 / 514" class="border w-100" />
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
            show: false,
        }
    },
    methods:{
        open(){
            this.show = true
        },
        close(){
            this.show = false
        }
    }
}
</script>

<style lang="scss" scoped>
</style>