<template>
    <v-sheet outlined class="pa-20 rounded-sm page-text mt-40 mt-md-60">
        <v-row class="row--xs">
            <template v-for="(item, index) in guides">
                <v-col :key="index" cols="12">
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <v-sheet width="4" height="4" rounded="circle" color="grey darken-4" class="mt-6 mt-md-8" />
                        </v-col>
                        <v-col>
                            <v-row class="row--xs">
                                <v-col cols="12">
                                    {{ item.text }}
                                </v-col>
                                <template v-if="item.children">
                                    <template v-for="(child, index) in item.children">
                                        <v-col :key="index" cols="12">
                                            <div class="font-size-12 grey--text">·&nbsp;{{ child }}</div>
                                        </v-col>
                                    </template>
                                </template>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    data: () => ({
        guides: [
            {
                text: "비밀번호 규정",
                children: [
                    "최소 8자리 이상: 영어, 소문자, 숫자, 특수문자 중 3종류 조합",
                    //
                ],
            },
            {
                text: "다른 사이트에서 사용하는 것과 동일하거나 쉬운 비밀번호는 사용하지 마세요.",
            },
            {
                text: "안전한 계정 사용을 위해 비밀번호는 주기적으로 변경해주세요.",
            },
        ],
    }),
};
</script>