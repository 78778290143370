<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <v-sheet color="grey lighten-5" class="mypage-home-background" />

            <v-row justify="center">
                <template v-for="(item, index) in items">
                    <v-col :key="index" cols="12" md="4">
                        <v-card :to="item.link" outlined flat class="rounded-sm pa-18 pa-md-24">
                            <v-row align="center">
                                <v-col>
                                    <div class="tit tit--xs font-weight-bold">
                                        {{ item.title }}
                                    </div>
                                    <div class="d-flex mx-n4 py-12 py-md-16">
                                        <template v-for="n in 3">
                                            <div :key="n" class="px-4">
                                                <v-sheet width="4" height="4" rounded="circle" color="primary" />
                                            </div>
                                        </template>
                                    </div>
                                    <div v-html="item.text" class="page-text grey--text" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon width="56" height="56" class="primary">
                                        <v-img src="/images/icon/icon-white-right.svg" max-width="8" />
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
    },
    data: () => ({
        items: [
            {
                title: "대관예약",
                text: "대관예약 현황을 <br>확인하세요.",
                link: "/mypage/reservation",
            },
            {
                title: "회원정보 수정",
                text: "가입하신 정보의 수정과 <br>변경이 가능합니다.",
                link: "/mypage/myinfo",
            },
            {
                title: "비밀번호 변경",
                text: "새로운 비밀번호로 변경하여 <br>안전하게 이용하세요.",
                link: "/mypage/password",
            },
            {
                title: "1:1문의",
                text: "문의글을 남기면 <br>답변해드립니다.",
                link: "/mypage/inquiry",
            },
            {
                title: "회원탈퇴",
                text: "탈퇴 후에는 모든 정보가 <br>삭제되오니 신중하게 결정해주세요.",
                link: "/mypage/withdrawal",
            },
        ],
    }),
    mounted() {
        // this.init();
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    methods: {
        async init() {
            // if (this.loading) return;
            // else this.loading = true;
            // try {
            //     if (!this.accessToken) {
            //         this.$router.push({
            //             path: "/login",
            //             params: this.$router.params,
            //         });
            //         return;
            //     }

            //     var { user } = await api.v1.me.get();

            //     if (user?.type == USER_TYPES.INDIVIDUAL) {
            //         this.form = initUserIndividual(user);
            //     }
            //     if (user?.type == USER_TYPES.ORGANIZATION) {
            //         this.form = initUserOrganization(user);
            //     }
            // } finally {
            //     this.loading = false;
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
.page-section {
    position: relative;
}
.mypage-home-background {
    width: 200vw;
    height: 430px;
    position: absolute;
    top: 0;
    left: -50vw;
    z-index: -1;
}
@media (min-width: 768px) {
    .mypage-home-background {
        height: 356px;
    }
}
</style>
