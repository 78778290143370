var render = function render(){
  var _vm$question, _vm$question2, _vm$question2$created, _vm$question3, _vm$question4, _vm$question5, _vm$question6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s((_vm$question = _vm.question) === null || _vm$question === void 0 ? void 0 : _vm$question.subject))])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v(" 작성일 : " + _vm._s((_vm$question2 = _vm.question) === null || _vm$question2 === void 0 ? void 0 : (_vm$question2$created = _vm$question2.createdAt) === null || _vm$question2$created === void 0 ? void 0 : _vm$question2$created.toDate()) + " ")]), _c('li', [_vm._v("상태 : 답변 " + _vm._s((_vm$question3 = _vm.question) !== null && _vm$question3 !== void 0 && _vm$question3.reply ? "완료" : "대기"))])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    staticClass: "white-space-pre-line"
  }, [_vm._v(_vm._s((_vm$question4 = _vm.question) === null || _vm$question4 === void 0 ? void 0 : _vm$question4.content))])])])]), (_vm$question5 = _vm.question) !== null && _vm$question5 !== void 0 && _vm$question5.reply ? _c('div', {
    staticClass: "grey lighten-5",
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "16",
      "src": "/images/icon/icon-indicator.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "white-space-pre-line"
  }, [_vm._v(_vm._s((_vm$question6 = _vm.question) === null || _vm$question6 === void 0 ? void 0 : _vm$question6.reply))])])], 1)], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "mt-24"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }