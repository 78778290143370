var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "pa-20 rounded-sm page-text mt-40 mt-md-60",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_vm._l(_vm.guides, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-sheet', {
      staticClass: "mt-6 mt-md-8",
      attrs: {
        "width": "4",
        "height": "4",
        "rounded": "circle",
        "color": "grey darken-4"
      }
    })], 1), _c('v-col', [_c('v-row', {
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.children ? [_vm._l(item.children, function (child, index) {
      return [_c('v-col', {
        key: index,
        attrs: {
          "cols": "12"
        }
      }, [_c('div', {
        staticClass: "font-size-12 grey--text"
      }, [_vm._v("· " + _vm._s(child))])])];
    })] : _vm._e()], 2)], 1)], 1)], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }