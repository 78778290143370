<template>
    <v-row>
        <v-col cols="12" md="4">
            <!-- 썸네일 -->
            <v-img :src="program?.thumb?.src || '/images/sub/program/list/program-item-default.jpg'" max-width="440" :aspect-ratio="1 / 1" class="w-100 rounded" />
        </v-col>
        <v-col cols="12" md="8">
            <v-layout fill-height column justify-space-between class="pl-md-20">
                <div>
                    <v-layout align-center class="mb-12 mb-md-16">
                        <!-- 교육명 -->
                        <h4 class="tit pr-10">{{ program.name }}</h4>

                        <!-- 교육종료 -->
                        <template v-if="program.state == PROGRAM_STATES.FINISHED.value">
                            <v-chip small dark color="grey" class="v-chip--noactive">
                                {{ program.stateText }}
                            </v-chip>
                        </template>
                        <!-- 진행중 -->
                        <template v-else>
                            <v-chip small outlined color="grey" class="v-chip--noactive">
                                {{ program.stateText }}
                            </v-chip>
                        </template>
                    </v-layout>
                    <div class="mb-n6 mb-md-n10">
                        <info-row-inline title="교육유형" align="center" class="mb-6 mb-md-10">
                            <!-- 카테고리 -->
                            <v-chip small color="grey lighten-4" class="v-chip--noactive grey--text">{{ program.category?.name }}</v-chip>
                        </info-row-inline>
                        <info-row-inline title="교육대상" align="center" class="mb-6 mb-md-10">
                            <!-- 회원등급 -->
                            <template v-if="isForAllUserTypes">
                                <v-chip small color="secondary" class="v-chip--noactive">전체회원</v-chip>
                            </template>
                            <template v-else v-for="text in userTypeTexts">
                                <v-chip :key="text" small color="primary" class="v-chip--noactive">
                                    {{ text }}
                                </v-chip>
                            </template>
                        </info-row-inline>
                        <info-row-inline title="교육일자" class="mb-6 mb-md-10"> {{ date }} ({{ unit?.day }}) </info-row-inline>
                        <info-row-inline title="교육시간" class="mb-6 mb-md-10"> {{ unit?.timeStart }} ~ {{ unit?.timeEnd }} </info-row-inline>
                        <info-row-inline title="현재상태" class="mb-6 mb-md-10">
                            <!-- 모집현황 -->
                            <span class="secondary--text font-weight-medium">{{ program.application.stateText }}</span> (총 교육인원 {{ program.capacity?.format?.() }}명 / 현재 참여 {{ program?.meta?.summary?.applications__active || 0 }}명)
                        </info-row-inline>
                    </div>
                </div>
                <div class="mt-20">
                    <slot name="action-btn" />
                </div>
            </v-layout>
        </v-col>
    </v-row>
</template>

<script>
import { initProgram, initProgramUnit, PROGRAM_STATES, USER_TYPES } from "@/assets/variables";

import InfoRowInline from "@/components/client/sub/info-row-inline.vue";

export default {
    components: {
        InfoRowInline,
    },
    props: {
        program: { type: Object, default: initProgram },
    },
    data: () => ({
        PROGRAM_STATES,
    }),
    computed: {
        userTypeTexts() {
            return [...new Set(this.program.userTypeTexts?.split?.(", "))];
        },
        isForAllUserTypes() {
            return !Object.values(USER_TYPES).some((type) => !this.program.userTypes.includes(type));
        },
        unit() {
            return initProgramUnit(this.program.units[0]);
        },
        date() {
            return this.unit?.date?.toDate?.()?.replaceAll?.("-", ".");
        },
    },
};
</script>

<style>
</style>