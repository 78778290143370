var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "마이페이지"
    }
  }), _c('mypage-navigation'), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "불참 기록"
    }
  }, [_c('penalty-info', {
    staticClass: "mb-20 mb-md-40"
  }), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.attendance.isAttended`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('p', {
          staticClass: "font-weight-bold"
        }, [value === null ? [_c('span', {
          staticClass: "grey--text"
        }, [_vm._v("대기")])] : _vm._e(), value === true ? [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("출석")])] : _vm._e(), value === false ? [_c('span', {
          staticClass: "error--text"
        }, [_vm._v("불참")])] : _vm._e()], 2)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "v-pagination-wrap"
        }, [_c('v-pagination', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": "11"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: {
                  page
                }
              });
            }
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }