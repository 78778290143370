<template>
    <v-form v-model="isValid">
        <client-page>
            <!-- 서브비주얼 -->
            <sub-visual sh="마이페이지" />
            <mypage-navigation />

            <page-section class="page-section--first page-section--last">
                <div class="font-size-24 font-size-md-28 mb-16 mb-md-20">비밀번호 재설정</div>
                <table-primary>
                    <table-row title="비밀번호">
                        <v-password-field v-model="password" :rules="rules.password" placeholder="새로운 비밀번호를 입력하세요." outlined />
                    </table-row>
                    <table-row title="비밀번호 확인">
                        <v-password-confirm-field type="password" :password="password" placeholder="비밀번호를 재입력 하세요." outlined />
                    </table-row>
                </table-primary>

                <password-rule />

                <!-- 하단버튼 -->
                <div class="v-btn--group">
                    <v-row justify="center" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn large rounded dark color="primary" class="rounded-xs" :disabled="disabled" @click="recovery">
                                <span>수정 완료</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </page-section>
        </client-page>
    </v-form>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { rules } from "@/assets/variables";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";
import TableRow from "@/components/dumb/table-row.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import PasswordRule from "@/components/client/join/password-rule.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        PageSection,
        TablePrimary,
        TableRow,
        VPasswordField,
        PasswordRule,
        VPasswordConfirmField,
    },
    data: () => ({
        rules,
        password: "",
        isValid: false,
        loading: false,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        disabled() {
            return !this.isValid;
        },
    },
    methods: {
        async recovery() {
            if (this.loading) return;
            this.loading = true;
            try {
                if (!this.validate) return;

                let password = CryptoAES.encrypt(this.password);
                await api.v1.me.put({ password });

                alert("비밀번호가 수정되었습니다.");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validate() {
            if (!this.password) throw new Error("비밀번호를 입력해주세요");

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-text-field {
        height: auto !important;
        min-height: 36px !important;
        .v-input__slot {
            margin-bottom: 0 !important;
            min-height: initial !important;
        }
        .v-messages__message {
            margin-bottom: 4px;
        }
    }
    @media (min-width: 768px) {
        .v-text-field {
            min-height: 48px !important;
        }
    }
}
</style>