<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <v-row justify="center" class="row--xl">
                <v-col cols="12">
                    <v-sheet outlined class="rounded-xs pa-16 pa-md-20">
                        <div class="font-size-24 font-size-28 mb-10 mb-md-12">제4장 서비스 제공 및 이용</div>
                        <v-row class="row--xxs page-text">
                            <v-col cols="auto">
                                <v-sheet width="4" height="4" color="grey darken-4" rounded="circle" class="mt-8" />
                            </v-col>
                            <v-col>
                                <div class="grey--text text--darken-4 mb-10">제11조 서비스 이용 해지</div>

                                <div class="grey--text">
                                    <v-row no-gutters>
                                        <v-col cols="auto">1.&nbsp;</v-col>
                                        <v-col>회원이 사이트와의 이용계약을 해지하고자 하는 경우에는 회원 본인이 온라인을 통하여 등록해지 신청을 하여야 합니다. 한편, 사이트 이용 해지와 별개로 사이트에 대한 이용계약 해지는 별도로 하셔야 합니다.</v-col>
                                    </v-row>
                                    <br />
                                    <v-row no-gutters>
                                        <v-col cols="auto">2.&nbsp;</v-col>
                                        <v-col>해지 신청과 동시에 사이트가 제공하는 사이트 관련 프로그램이 회원 관리 화면에서 자동적으로 삭제됨으로 운영자는 더 이상 해지신청자의 정보를 볼 수 없습니다.</v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex justify-center">
                        <v-checkbox v-model="agreement" hide-details label="해당 내용을 모두 확인하였으며, 탈퇴에 동의합니다."></v-checkbox>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex justify-center">
                        <v-btn large dark rounded color="grey" class="rounded-xs" @click="withdrawl">탈퇴 진행</v-btn>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
    },
    data: () => ({
        agreement: false,
    }),
    methods: {
        async withdrawl() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.validate()) return;
                if (confirm("탈퇴를 진행하시겠습니까?")) {
                    await api.v1.me.withdraw({});
                    alert("탈퇴처리 되었습니다");

                    this.$store.dispatch("logout").then(() => {
                        this.$router.push("/");
                    });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validate() {
            if (!this.agreement) throw new Error("탈퇴 약관에 동의해주세요.");
            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
