<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <v-form v-model="isValid">
                <v-row class="row--xl">
                    <v-col cols="12">
                        <div class="font-size-24 font-size-md-28 mb-16 mb-md-20">필수입력 정보</div>
                        <table-primary>
                            <table-row title="아이디">
                                {{ form.username }}
                            </table-row>
                            <table-row title="이름">
                                {{ form.name }}
                            </table-row>
                            <table-row title="닉네임" pointer>
                                <v-text-field v-model="form.nickname" :rules="rules.nickname" placeholder="닉네임을 입력하세요." outlined @input="emit" />
                            </table-row>
                            <table-row title="성별" pointer>
                                <v-gender-radio-group v-model="form.gender" @input="emit" />
                            </table-row>
                            <table-row title="연락처" pointer>
                                <v-phone-field v-model="form.phone" hasCertification disabled @certify="edit" />
                            </table-row>
                            <table-row title="이메일" pointer>
                                <v-email-field v-model="form.email" :rules="rules.email" @input="emit" />
                            </table-row>
                        </table-primary>
                    </v-col>
                    <v-col cols="12">
                        <div class="font-size-24 font-size-md-28 mb-16 mb-md-20">부가입력 정보</div>
                        <table-primary>
                            <table-row title="생년월일">
                                <!-- <v-text-field v-model="form.birthday" disabled placeholder="생년월일 6자리를 입력하세요. (ex.940118)" /> -->
                                {{ form?.birthday?.slice(2) }}
                            </table-row>
                            <table-row title="행사 및 관련 소식 <br class='d-none d-md-block' />수신여부">
                                <v-news-radio-group v-model="form.marketingMethod" @input="emit" />
                            </table-row>
                            <table-row title="주소">
                                <v-address-field v-model="form.address" :noRules="true" @input="emit" />
                            </table-row>
                        </table-primary>
                    </v-col>
                </v-row>

                <!-- 하단버튼 -->
                <div class="v-btn--group">
                    <v-row no-gutters justify="center">
                        <v-col cols="auto">
                            <v-btn large rounded dark color="primary" class="rounded-xs" :disabled="disabled" @click="save">
                                <span>수정 완료</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </page-section>
        <v-fade-transition>
            <v-overlay v-show="loading" light color="white" opacity="0.2">
                <v-progress-circular size="100" width="5" indeterminate color="primary" />
            </v-overlay>
        </v-fade-transition>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { initAddress, initUser, rules } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

import TableRow from "@/components/dumb/table-row.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";

import DialogCertExample from "@/components/client/join/dialog-cert-example.vue";
import EquipmentAgreementDownload from "@/components/client/join/equipment-agreement-download.vue";

import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";
import VGenderRadioGroup from "@/components/plugins/vuetify/v-gender-radio-group.vue";
import VNewsRadioGroup from "@/components/plugins/vuetify/v-news-radio-group.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,

        TableRow,
        TablePrimary,

        DialogCertExample,
        EquipmentAgreementDownload,

        VPhoneField,
        VEmailField,
        VGenderRadioGroup,
        VNewsRadioGroup,
        VAddressField,
    },
    data: () => ({
        form: initUser(),
        rules,

        loading: false,
        isValid: false,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                var { user } = await api.v1.me.get();

                let { postcode, address1, address2 } = user;

                this.form = initUser({ ...user, address: initAddress({ postcode, address1, address2 }) });
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.form = initUser(this.form);
        },

        async save() {
            try {
                this.validate();
            } catch (error) {
                alert(error.message);
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                ////////////////////////////////////////////////////////////////
                // 폼데이터 정돈
                ////////////////////////////////////////////////////////////////
                let { address, ...form } = this.form;
                form = initUser(this.form);
                ////////////////////////////////////////////////////////////////
                // 회원 업데이트
                ////////////////////////////////////////////////////////////////
                const { user } = await api.v1.me.put({ ...form, ...address });

                alert("수정되었습니다");
                this.init();
            } finally {
                this.loading = false;
            }
        },

        edit(payload) {
            let { ci, name, phone, birthday, _certification } = payload;

            this.form = initUser({ ...this.form, _certification, name, phone: phone.phoneNumberFormat(), birthday, ci: CryptoAES.encrypt(ci.replace(/[ ]/g, "+")) });
        },

        validate() {
            if (!this.form.username) throw new Error("아이디를 입력해주세요.");

            if (!this.form.name) throw new Error("이름을 입력해주세요.");
            if (!this.form.phone) throw new Error("연락처를 입력해주세요.");
            if (!this.form.email) throw new Error("이메일을 입력해주세요.");
        },
    },
};
</script>

<style lang="scss" scoped>
.v-text-field {
    height: auto !important;
    min-height: 36px;
    ::v-deep {
        .v-input__slot {
            margin-bottom: 0;
        }
        .v-messages__message {
            margin-bottom: 4px;
        }
    }
}
@media (min-width: 768px) {
    .v-text-field {
        min-height: 48px;
    }
}
</style>