var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm.isAttended == null ? [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("대기")])] : _vm._e(), _vm.isAttended == true ? [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("출석")])] : _vm._e(), _vm.isAttended == false ? [_c('span', {
    staticClass: "error--text"
  }, [_vm._v("불참")])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }